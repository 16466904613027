<template>
</template>

<script>
import store from '@/store'

export default {
    name: 'AdminAuthLoginDoor',
    components: {
    },
    data: function() {
        return {
        }
    },


    async beforeRouteEnter(route, redirect, next) {
        // await store.dispatch("auth/logout")
        // store.commit("auth/logout")
        await store.dispatch('auth/authPoint')
    },

    methods: {
    }
}
</script>

<style scoped>
</style>

